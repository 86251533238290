import * as React from "react";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import SignUpMessage from "../../components/SignUpMessage";

const ThanksMessage = () => (
  <Layout>
    <Seo title="Thank You!" />
    <SignUpMessage />
  </Layout>
);

export default ThanksMessage;
